import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import { HTMLRenderer, Layout, PageWrapper, PrivacyPolicyWrapper } from "@retina-packages/retina-theme-bootstrap"
import { PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import  ArticleContent from '@retina-packages/retina-theme-bootstrap/packages/containers/ArticleContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { artCons } from './constants';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import retinaConfig from '../../../utils/retinaConfigs';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { compareUrls } from '@retina-packages/retina-theme-bootstrap/packages/helpers'
import { deriveMultiLogo, deriveSiteMenus, fullHTMLDataBuilder, footRefHTMLBuilder } from '../../../utils';
import {PrivacyPolicyHcpMain} from './styles';
import { EsHeaderContainer } from '../../../css/common/style';

export interface Props {
  data?: any;
  location?: any;
  title?: string;
  path?: any;
  pageContext?: any;
  isDefault?: any;
  locale?: any;
}

/** Rendering template for Primary block
 *
 * @param props props
 * @returns
 */
const PrimaryTemplate = (props: Props) => {

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsART = props.pageContext
  const htmlStaticFilesART = {
    nonSVGImages: pgContextsART.nonSVGImages,
    svgMediaImages: pgContextsART.svgMediaImages,
    allMediaDocument: pgContextsART.documents
  }
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const privacyPageUrl = pgContextsART.urlMap["/privacy-policy"].pathSlug
  const hcpPrivacyPageUrl = pgContextsART.urlMap["/hcp/privacy-policy"].pathSlug
  const sitemapPageUrl = pgContextsART.urlMap["/sitemap"].pathSlug
  const hcpSitemapPageUrl = pgContextsART.urlMap["/hcp/sitemap"].pathSlug
  const isdtcPrivacyPage = compareUrls(props.location.pathname, withPrefix(privacyPageUrl))
  const ishcpPrivacyPage = compareUrls(props.location.pathname, withPrefix(hcpPrivacyPageUrl))

  let topMenuLabel = ""
  let foorMenuLabel = ""
  let footerTitle = ""
  let gtmBackToTopLabel = ""
  let addHcpPrefix = false
  let topNavTitle = ""
  const logoLabel = artCons.siteLogo
  let siteMapLevel = ""
  let bannerTitle = ""
  let pagewrapper = ""
  let hcpSitemapPage =""
  let clsNameForprivacypolicyhcp = ""
  let clsNameForprivacypolicyhcpPage = ""
  let exitPopupTitle = ""
  let layoutAttrs;
  if (audience === "Professional") {
    topMenuLabel = artCons.hcpMainMenu
    foorMenuLabel = artCons.hcpFooterMenu
    footerTitle = artCons.hcpFooter
    topNavTitle = artCons.hcpTopNav
    addHcpPrefix = retinaConfig.addHcpPrefix
    siteMapLevel = artCons.hcpSitemapLabel
    exitPopupTitle = artCons.hcpExitPopup
    layoutAttrs = {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
    if (ishcpPrivacyPage) {
      bannerTitle = artCons.bannerPrivacyHCP
      pagewrapper = "privacy-site"
      gtmBackToTopLabel = artCons.backToTopGTMPPHCP
      clsNameForprivacypolicyhcp = "privacypolicyhcp"
      clsNameForprivacypolicyhcpPage = "privacypolicyhcppagewrap"
    }
    else {
      bannerTitle = artCons.bannerSitemapHCP
      pagewrapper = "sitemap"
      gtmBackToTopLabel= artCons.backToTopGTMSMHCP
      hcpSitemapPage = "hcpsitemappage"
    }
  }
  else {
    topMenuLabel = artCons.patMainMenu
    foorMenuLabel = artCons.patFooterMenu
    footerTitle = artCons.patFooter
    topNavTitle = artCons.patTopNav
    siteMapLevel = artCons.patSitemapLabel
    exitPopupTitle = artCons.dtcExitPopup
    layoutAttrs = {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
    if (isdtcPrivacyPage) {
      bannerTitle= artCons.bannerPrivacyDTC
      pagewrapper = "privacy-site"
      gtmBackToTopLabel = artCons.backToTopGTMPP
    }
    else {
      bannerTitle= artCons.bannerSitemapDTC
      pagewrapper = "sitemap"
      gtmBackToTopLabel= artCons.backToTopGTMSM
    }

  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: topMenuLabel, siteFooterMenu: foorMenuLabel, addHCPPrefix: addHcpPrefix });

  const carouselTitle = artCons.carousel
  const hcpSwitcherTitle = artCons.hcpSwitcher
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle: "", carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const dataSetArt = fullHTMLDataBuilder({ blocks, title: artCons.privacyPara });
  const siteMapFullHTMLArt = fullHTMLDataBuilder({ blocks, title: siteMapLevel });
  const siteLogos: any = deriveMultiLogo({ blocks, title: logoLabel })
  const bannerHTML = fullHTMLDataBuilder({ blocks: blocks, title: bannerTitle });
  const socialBlock = footRefHTMLBuilder({ blocks, title: artCons.socialBlock });
  return (
    <EsHeaderContainer>
    <PrivacyPolicyHcpMain className="privacy-policy-main-wrapper">
    <PrivacyPolicyWrapper className={`footer ${clsNameForprivacypolicyhcp} ${hcpSitemapPage}`}>
      <div>
        {props?.pageContext !== null && props?.pageContext.metaInfo !== null && (
          <MetaTagContainer metaData={props?.pageContext?.metaInfo} />
        )}
        <Layout
          title={"siteTitle"}
          location={props.location}
          data={mainMenu}
          mdata={footerMenu}
          socialBlock={socialBlock}
          footerData={footerText}
          audience={audience}
          exitPopData={exitData}
          hcplinks={hcpSwitcher}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          gtmBackToTopLabel = {gtmBackToTopLabel}
          backToTopContent={retinaConfig.backToTopContent}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          staticMediaFiles={htmlStaticFilesART}
          hcpValidate={retinaConfig.hcpValidate}
          preIndexUrl = {retinaConfig.preIndexUrl}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={artCons.footerClassName}
          cpraFooter={true}
          cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
          cpraOT={true}
          cpraOTEnv={process.env.OT_ENV}
          cpraOTuuid={process.env.OT_UUID}
          {...layoutAttrs}
        >
          <PageWrapper className={`pagewrapper ${pagewrapper}  ${clsNameForprivacypolicyhcpPage}`}>
            <MobileBanner className="mobile-banner clinical-trials-banner common-banner">
              <HTMLRenderer data={htmlStaticFilesART} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
            </MobileBanner>
            <ArticleContent data={dataSetArt} path={props.path} mainMenu={mainMenu} footerMenu={footerMenu} audience={audience} location={props.location} hcplinks={hcpSwitcher} topNavigation={topNavs} hcpHomeUrl={retinaConfig.hcpHomeUrl} siteMapContent={siteMapFullHTMLArt} privacyPageUrl={privacyPageUrl} hcpPrivacyPageUrl={hcpPrivacyPageUrl} sitemapPageUrl={sitemapPageUrl} hcpSitemapPageUrl={hcpSitemapPageUrl} />
          </PageWrapper>
        </Layout>
      </div>
    </PrivacyPolicyWrapper>
    </PrivacyPolicyHcpMain>
    </EsHeaderContainer>
    )
}

export default PrimaryTemplate

// Page query in GraphQL
export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...ArticlePageQuery
      }
    }
  }
`