import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const PrivacyPolicyHcpMain = styled.div`
.privacypolicyhcp{
  .privacy-site{
    .internal-banner{
      .internal-banner__contant{
        .o-container{
          h1{
            margin-top: -20px;
            @media ${device.ipadLandscapemin} {
              margin-top: -8px;
            }
            @media ${device.desktopsignup} {
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
  .mobile-banner{
    &:after{
      @media ${device.mobileS} and ${device.laptopMedium} {
        height: calc(100% + 30px);
      }
    }
  }
  .privacypolicycontent{
    @media ${device.ipadLandscapemin}{
      margin-top: -20px;
      padding: 0 5rem 80px;
    }

    .intro-section{
      padding: 0 2.7rem;

      .o-header--h2{
        color: var(--bs-body-color);
        @media ${device.tablet}{
          margin-top: 45px;
        }
        @media ${device.ipadLandscapemin} {
          margin-top: 60px;
        }
      }
    }
    .o-container{
      padding: 0px 2.7rem !important;
      @media ${device.ipadLandscapemin}{
        padding: 0 2rem !important;
      }
      @media ${device.desktopsignup}{
        padding: 0 2rem 0 3rem !important;
      }
    }
    .o-paragraph{
      @media ${device.ipadLandscapemin}{
        font-size: 2.2rem;
        line-height: 3.2rem;
        font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
      }

    &.o-para-fcb-bold{
      font-size: 2rem;
      margin: 0 0 20px;
      @media ${device.ipadLandscapemin}{
        font-size: 2.7rem;
        line-height: 3.8rem;
        font-weight: 700;
        font-family: 'Gotham', Arial, Helvetica, sans-serif;
      }
    }
    }
  }
  .o-container{
    max-width: 1208px !important;
  }
  .privacypolicyhcppagewrap{
    &:before{
      @media ${device.ipadLandscapemin}{
        top: 5px;
      }
      @media ${device.desktopStart}{
        top: 42px;
        height: calc(100% - 260px) !important;
      }
    }
  }
}`;