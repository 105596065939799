export const artCons: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO - Retina Spain",
  patMainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  hcpFooter: "Footer HCP - Retina Spain",
  patFooter: "Footer Consumer - Retina Spain",
  dtcExitPopup: "Exit Popup - DTC - Retina Spain",
  hcpExitPopup: "Exit Popup - HCP - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  hcpTopNav: "Top Navigational Menu HCP - Retina Spain",
  patTopNav: "Top Navigational Menu Consumer - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  hcpSitemapLabel: "Retina HCP Sitemap - Full HTML - Retina Spain",
  patSitemapLabel: "Retina DTC Sitemap - Full HTML - Retina Spain",
  privacyPara: "Privacy Policy - Retina Spain",
  bannerSitemapHCP: "Banner - HTML - Sitemap - Retina Spain",
  bannerSitemapDTC: "Banner - HTML - Sitemap - Retina Spain",
  bannerPrivacyHCP: "Banner - HTML - Privacy Policy - Retina Spain",
  bannerPrivacyDTC: "Banner - HTML - Privacy Policy - Retina Spain",
  backToTopGTMPP: "back to top - privacy policy",
  backToTopGTMSM: "back to top - site map",
  backToTopGTMSMHCP: "back to top - site map - hcp",
  backToTopGTMPPHCP: "back to top - privacy policy",
  footerClassName: "common-footer"
}